import React from "react";
import "./CompanyProfileRow.scss";
import Icons from "../../../../../Icons/Icons";
import { getFormatFIO } from "../../../../../../utils/getFormatFIO";
import { useSelector } from "react-redux";
import { useHasPermissions } from "../../../../../../hooks/useHasPermissions";
import { useModal } from "../../../../../../hooks/useModal";

const CompanyProfileRow = ({ info }) => {
  const { name, profiles } = info;
  const { changeModalVisible } = useModal();

  const { company } = useSelector((state) => state.companies);

  const { edit: editPermission } = useHasPermissions("companiesCard", company);

  const createUser = () => {
    return profiles
      .map((profile) => {
        return getFormatFIO(profile.user, "full");
      })
      .join(", ");
  };

  const onEvent = (name) => {
    if (name === "info") {
      return changeModalVisible("infoEditProfiles", true, info);
    }
    if (name === "edit") return changeModalVisible("infoEditProfiles", true, info, true);
  };

  return (
    <ul className="companyProfileRow">
      <li
        className="companyProfileRow-item companyProfileRow-item-name"
        onClick={() => onEvent("info")}
      >
        <span>{name}</span>
      </li>
      <li className="companyProfileRow-item companyProfileRow-item" title={createUser()}>
        <span>{createUser()}</span>
      </li>

      <li className="companyProfileRow-item companyProfileRow-item-btns">
        <button className="employeesListRow__btn" onClick={() => onEvent("info")}>
          <Icons type={"infoItem"} size={"md"} cursor title={{ visible: true, text: "" }} />
        </button>
        {editPermission && (
          <button className="employeesListRow__btn" onClick={() => onEvent("edit")}>
            <Icons type={"edit"} size={"md"} cursor title={{ visible: true, text: "" }} />
          </button>
        )}
      </li>
    </ul>
  );
};

export default CompanyProfileRow;
