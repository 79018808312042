import { useEffect } from "react";

// const getClassesTree = (elem, nesting = 4) => {
//   const classes = []

//   let currentEl = elem.target

//   for (let i = 0; i < nesting; i++) {
//     classes.push(currentEl.className)
//     currentEl = currentEl.parentElement
//   }

//   return classes

// }

export function useOnClickOutside(ref, handler) {
  useEffect(
    () => {
      const listener = (event) => {
        // Do nothing if clicking ref's element or descendent elements
        if (
          !ref.current ||
          ref.current.contains(event.target) ||
          !event.srcElement.parentElement.className.search("Toastify") ||
          !event.srcElement.className.search("Toastify")
        ) {
          return;
        }
        handler(event);
      };
      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);
      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler],
  );
}
