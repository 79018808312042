import React from "react";
import "./CompanyResponsibleRow.scss";
import { getFormatFIO } from "../../../../../../utils/getFormatFIO";
import Icons from "../../../../../Icons/Icons";
import { useModal } from "../../../../../../hooks/useModal";
import { useHasPermissions } from "../../../../../../hooks/useHasPermissions";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const CompanyResponsibleRow = ({ info }) => {
  const { name, responsible } = info;
  const { changeModalVisible } = useModal();

  const navigate = useNavigate();

  const { company } = useSelector((state) => state.companies);

  const { edit: editPermission } = useHasPermissions("companiesCard", company);

  const onEvent = (name) => {
    if (name === "project") {
      navigate(`/project/${info?._id}/basicInformation`);
    }
    if (name === "userInfo") {
      navigate(`/userInfo/${responsible?.user?._id}/info`);
    }
    if (name === "delete") {
      return changeModalVisible("deleteResponible", true, info);
    }
    if (name === "edit") return changeModalVisible("addEditResponible", true, info, true);
  };

  return (
    <div className="companyResponsibleRow">
      <li
        className="companyResponsibleRow-item companyResponsibleRow-item-name"
        onClick={() => onEvent("project")}
      >
        <span>{name}</span>
      </li>
      <li
        className="companyResponsibleRow-item companyResponsibleRow-item-name"
        onClick={() => onEvent("userInfo")}
      >
        <span>{getFormatFIO(responsible.user, "full")}</span>
      </li>
      {editPermission && (
        <li className="companyProfileRow-item companyProfileRow-item-btns">
          <button className="employeesListRow__btn" onClick={() => onEvent("edit")}>
            <Icons type={"edit"} size={"md"} cursor title={{ visible: true, text: "" }} />
          </button>
          <button className="employeesListRow__btn" onClick={() => onEvent("delete")}>
            <Icons type={"remove"} size={"md"} cursor title={{ visible: true, text: "" }} />
          </button>
        </li>
      )}
    </div>
  );
};

export default CompanyResponsibleRow;
