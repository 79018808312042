import React, { useEffect, useState } from "react";
import { ReactComponent as InfoItem } from "../../assets/icons/infoItem.svg";
import { ReactComponent as Edit } from "../../assets/icons/editItem.svg";
import { ReactComponent as Remove } from "../../assets/icons/removeItem.svg";
import { ReactComponent as ViewCard } from "../../assets/icons/viewCard.svg";
import { ReactComponent as GearSettings } from "../../assets/icons/gearSettings-black.svg";
import { ReactComponent as FilterIcon } from "../../assets/icons/filter.svg";
import { ReactComponent as CrossBlackIcon } from "../../assets/icons/crossBlackIcon.svg";
import { ReactComponent as CrossBlack } from "../../assets/icons/crossBlackIcon.svg";
import { ReactComponent as VoiceMicro } from "../../assets/icons/voice-micro-black.svg";
import { ReactComponent as PaperIcon } from "../../assets/icons/paper-clip-black.svg";
import { ReactComponent as EmailOpen } from "../../assets/icons/emailOpen.svg";
import { ReactComponent as TasksIcon } from "../../assets/icons/tasks-list-black.svg";
import { ReactComponent as ArrowLeft } from "../../assets/icons/arrow-left-black.svg";
import { ReactComponent as ArrowTop } from "../../assets/icons/arrow-up-black.svg";
import { ReactComponent as CloseWhite } from "../../assets/icons/cross-close-white.svg";
import { ReactComponent as Menu } from "../../assets/icons/menu-three-dots.svg";
import { ReactComponent as MenuWhite } from "../../assets/icons/menuWhite.svg";
import { ReactComponent as AddToFolder } from "../../assets/icons/addToFolder-black.svg";
import { ReactComponent as Download } from "../../assets/icons/downland-black.svg";
import { ReactComponent as ColumnsChart } from "../../assets/icons/columnsChart.svg";
import { ReactComponent as BandsChart } from "../../assets/icons/bandsChart.svg";
import { ReactComponent as AreasChart } from "../../assets/icons/areasChart.svg";
import { ReactComponent as StepAreasChart } from "../../assets/icons/stepAreasChart.svg";
import { ReactComponent as LinesChart } from "../../assets/icons/linesChart.svg";
import { ReactComponent as PieChart } from "../../assets/icons/pieChart.svg";
import { ReactComponent as DonutChart } from "../../assets/icons/donutChart.svg";
import { ReactComponent as ListChart } from "../../assets/icons/listChart.svg";
import { ReactComponent as Structures } from "../../assets/icons/structures.svg";
import { ReactComponent as Copy } from "../../assets/icons/copy.svg";
import { ReactComponent as TimeIcon } from "../../assets/icons/time-black.svg";
import { ReactComponent as Arrow } from "../../assets/icons/arrowForSwiper-black.svg";
import { ReactComponent as ArrowDown } from "../../assets/icons/arrow-down-black.svg";
import { ReactComponent as DateIcon } from "../../assets/icons/calendarPeriod.svg";
import { ReactComponent as Phone } from "../../assets/icons/phone-white.svg";
import { ReactComponent as Email } from "../../assets/icons/email-white.svg";
import { ReactComponent as ArrowDouble } from "../../assets/icons/arrowDouble.svg";
import { ReactComponent as All } from "../../assets/icons/all.svg";
import { ReactComponent as Outgoing } from "../../assets/icons/outgoing.svg";
import { ReactComponent as Incoming } from "../../assets/icons/incoming.svg";
import { ReactComponent as DocProject } from "../../assets/icons/docProject.svg";
import { ReactComponent as DocAll } from "../../assets/icons/docAll.svg";
import { ReactComponent as Structure } from "../../assets/icons/structure.svg";
import { ReactComponent as Groups } from "../../assets/icons/groups.svg";
import { ReactComponent as Members } from "../../assets/icons/members.svg";
import { ReactComponent as Layers } from "../../assets/icons/layers.svg";
import { ReactComponent as Docs } from "../../assets/icons/docs.svg";
import { ReactComponent as Protocols } from "../../assets/icons/protocols.svg";
import { ReactComponent as Company } from "../../assets/icons/company.svg";
import { ReactComponent as Graf } from "../../assets/icons/gantt.svg";
import { ReactComponent as Foto } from "../../assets/icons/foto.svg";
import { ReactComponent as MyProject } from "../../assets/icons/myProject.svg";
import { ReactComponent as MemberProject } from "../../assets/icons/memberProject.svg";
import { ReactComponent as AllProject } from "../../assets/icons/allProject.svg";
import { ReactComponent as Admin } from "../../assets/icons/admin.svg";
import { ReactComponent as Director } from "../../assets/icons/director.svg";
import { ReactComponent as User } from "../../assets/icons/user.svg";
import { ReactComponent as Performer } from "../../assets/icons/performer.svg";
import { ReactComponent as Customer } from "../../assets/icons/customer.svg";
import { ReactComponent as CircleMinus } from "../../assets/icons/circle-minus.svg";
import { ReactComponent as CirclePlus } from "../../assets/icons/circle-plus.svg";
import { ReactComponent as Employees } from "../../assets/icons/employees.svg";
import { ReactComponent as Tasks } from "../../assets/icons/tasks.svg";
import { ReactComponent as Projects } from "../../assets/icons/projects.svg";
import { ReactComponent as Profile } from "../../assets/icons/documents.svg";
import { ReactComponent as Documents } from "../../assets/icons/documents.svg";
import { ReactComponent as Сalendar } from "../../assets/icons/calendar.svg";
import { ReactComponent as Сhat } from "../../assets/icons/chat.svg";
import { ReactComponent as DeliveryWork } from "../../assets/icons/deliveryWork.svg";
import { ReactComponent as Analytics } from "../../assets/icons/analitics.svg";
import { ReactComponent as Reference } from "../../assets/icons/reference.svg";
import { ReactComponent as Notification } from "../../assets/icons/notifications.svg";
import { ReactComponent as Search } from "../../assets/icons/search.svg";
import { ReactComponent as ArrowForPagination } from "../../assets/icons/arrowForPagination.svg";
import { ReactComponent as EyeOpen } from "../../assets/icons/eyePassword.svg";
import { ReactComponent as EyeClose } from "../../assets/icons/eye-with-line.svg";
import { ReactComponent as Exit } from "../../assets/icons/exit.svg";
import { ReactComponent as AllowedIcon } from "../../assets/icons/allowed.svg";
import { ReactComponent as BannedIcon } from "../../assets/icons/banned.svg";
import { ReactComponent as Plus } from "../../assets/icons/plus.svg";
import { ReactComponent as Minus } from "../../assets/icons/minus.svg";
import { ReactComponent as SmallGrid } from "../../assets/icons/small-grid.svg";
import { ReactComponent as Grid } from "../../assets/icons/grid.svg";
import { ReactComponent as BigGrid } from "../../assets/icons/big-grid.svg";
import { ReactComponent as TwoChechmark } from "../../assets/icons/two-chechmart-green.svg";
import { ReactComponent as CircleOfArrows } from "../../assets/icons/circleOfArrows.svg";
import { ReactComponent as Pen } from "../../assets/icons/pen-yellow.svg";
import { ReactComponent as Overdue } from "../../assets/icons/overdue-red.svg";
import { ReactComponent as Cancelled } from "../../assets/icons/cancelled-grey.svg";
import { ReactComponent as Assigned } from "../../assets/icons/assigned-purple.svg";
import { ReactComponent as Notif } from "../../assets/icons/notif.svg";
import { ReactComponent as AvailabilityDoc } from "../../assets/icons/availabilityDoc.svg";
import { ReactComponent as Lock } from "../../assets/icons/lock.svg";
import { ReactComponent as Send } from "../../assets/icons/send.svg";
import { ReactComponent as MagnifierMinus } from "../../assets/icons/magnifierMinus-white.svg";
import { ReactComponent as MagnifierPlus } from "../../assets/icons/magnifierPlus-white.svg";
import { ReactComponent as MagnifierBlack } from "../../assets/icons/magnifier-black.svg";
import { ReactComponent as Window } from "../../assets/icons/window.svg";
import { ReactComponent as WindowDouble } from "../../assets/icons/windowDouble.svg";
import { ReactComponent as Comment } from "../../assets/icons/comment.svg";
import { ReactComponent as Forward } from "../../assets/icons/forward.svg";
import { ReactComponent as Folder } from "../../assets/icons/folder.svg";
import { ReactComponent as Zip } from "../../assets/icons/zip.svg";
import { ReactComponent as Xlsx } from "../../assets/icons/xlsx.svg";
import { ReactComponent as Xls } from "../../assets/icons/xls.svg";
import { ReactComponent as Txt } from "../../assets/icons/txt.svg";
import { ReactComponent as Ppt } from "../../assets/icons/ppt.svg";
import { ReactComponent as Pdf } from "../../assets/icons/pdf.svg";
import { ReactComponent as NoneFormat } from "../../assets/icons/noneFormat.svg";
import { ReactComponent as Mpp } from "../../assets/icons/mpp.svg";
import { ReactComponent as Jpg } from "../../assets/icons/jpg.svg";
import { ReactComponent as Jpeg } from "../../assets/icons/jpeg.svg";
import { ReactComponent as Docx } from "../../assets/icons/docx.svg";
import { ReactComponent as Doc } from "../../assets/icons/doc.svg";
import { ReactComponent as Png } from "../../assets/icons/png.svg";
import { ReactComponent as FolderOpen } from "../../assets/icons/folderOpen.svg";
import { ReactComponent as Save } from "../../assets/icons/save.svg";
import { ReactComponent as EditSave } from "../../assets/icons/editSave.svg";
import { ReactComponent as Reset } from "../../assets/icons/reset.svg";
import { ReactComponent as File } from "../../assets/icons/file.svg";
import { ReactComponent as FolderClear } from "../../assets/icons/folderClear.svg";
import { ReactComponent as SortArrow } from "../../assets/icons/sortArrow.svg";
import { ReactComponent as SuccessDouble } from "../../assets/icons/suucessDuoble.svg";
import { ReactComponent as Confirm } from "../../assets/icons/confirm.svg";
import { ReactComponent as ChangeSatus } from "../../assets/icons/changeSatus.svg";
import { ReactComponent as AddUser } from "../../assets/icons/addUser.svg";
import { ReactComponent as DelUser } from "../../assets/icons/delUser.svg";
import { ReactComponent as Cleaning } from "../../assets/icons/cleaning.svg";
import { ReactComponent as ViewCardWhite } from "../../assets/icons/viewCardWhite.svg";
import { ReactComponent as Hand } from "../../assets/icons/hand.svg";
import { ReactComponent as Fire } from "../../assets/icons/fire.svg";
import { ReactComponent as AddEvent } from "../../assets/icons/addEvent.svg";
import { ReactComponent as UserInfo } from "../../assets/icons/userInfo.svg";
import { ReactComponent as History } from "../../assets/icons/history.svg";
import { ReactComponent as Sort } from "../../assets/icons/bx-sort.svg";
import "./Icons.scss";

import { useSelector } from "react-redux";

const Icons = ({
  type,
  onClick,
  className,
  size,
  cursor = false,
  color,
  title = {
    text: "", // при пустом тексте отображатеся вписанный title
    visible: false,
  },
}) => {
  const { iconsTitle } = useSelector((state) => state.settings);
  const [titleText, setTitleText] = useState("");

  useEffect(() => {
    switch (type) {
      case "infoItem":
        return setTitleText("Информация");
      case "edit":
        return setTitleText("Редактировать");
      case "remove":
        return setTitleText("Удалить");
      case "settings":
        return setTitleText("Настройки");
      case "viewCard":
        return setTitleText("Изменить отображение");
      case "crossBlackIcon":
        return setTitleText("Закрыть");
      case "crossBlack":
        return setTitleText("Закрыть");
      case "filterIcon":
        return setTitleText("Фильтр");
      case "paperIcon":
        return setTitleText("Прикрепить файл");
      case "emailOpen":
        return setTitleText("Открыть письмо");
      case "tasksIcon":
        return setTitleText("Задачи");
      case "arrowLeft":
        return setTitleText("Влево");
      case "arrowTop":
        return setTitleText("Вверх");
      case "arrowDown":
        return setTitleText("Вниз");
      case "closeWhite":
        return setTitleText("Закрыть");
      case "closeBlack":
        return setTitleText("Закрыть");
      case "menu":
        return setTitleText("Меню");
      case "addToFolder":
        return setTitleText("Переместить в папку");
      case "download":
        return setTitleText("Скачать");
      case "columnsChart":
        return setTitleText("График: Колонны");
      case "bandsChart":
        return setTitleText("График: Полосы");
      case "areasChart":
        return setTitleText("График: Области");
      case "stepAreasChart":
        return setTitleText("График");
      case "linesChart":
        return setTitleText("График: Линии");
      case "pieChart":
        return setTitleText("График: Пирог");
      case "donutChart":
        return setTitleText("График: Пончик");
      case "structures":
        return setTitleText("Показать отделы");
      case "copy":
        return setTitleText("Копировать");
      case "timeIcon":
        return setTitleText("Время");
      case "timeIcon":
        return setTitleText("Время");
      case "search":
        return setTitleText("Поиск");
      case "arrowForPagination":
        return setTitleText("Следующая");
      case "eyeOpen":
        return setTitleText("Скрыть стрелки");
      case "eyeClose":
        return setTitleText("Показать стрелки");
      case "allowedIcon":
        return setTitleText("Доступно");
      case "bannedIcon":
        return setTitleText("Заблокировано");
      case "plus":
        return setTitleText("Увелчичить");
      case "grid":
        return setTitleText("Средний размер");
      case "big-grid":
        return setTitleText("Большой размер");
      case "small-grid":
        return setTitleText("Маленький размер");
      case "twoChechmark":
        return setTitleText("Выполненные");
      case "circleOfArrows":
        return setTitleText("В работе");
      case "pen":
        return setTitleText("На проверке");
      case "overdue":
        return setTitleText("Просроченные");
      case "cancelled":
        return setTitleText("Отмененные");
      case "assigned":
        return setTitleText("Назначенные");
      case "notif":
        return setTitleText("Уведомления");
      case "availabilityDoc":
        return setTitleText("Слой");
      case "lock":
        return setTitleText("Заблокированно");
      case "send":
        return setTitleText("Переслать");
      case "dateIcon":
        return setTitleText("Дата");
      case "magnifierMinus":
        return setTitleText("Уменьшить");
      case "magnifierPlus":
        return setTitleText("Отдалить");
      case "minus":
        return setTitleText("Уменьшить");
      case "comment":
        return setTitleText("Оставить коментарий");
      case "addEvent":
        return setTitleText("Добавить мероприятие");
      default:
        setTitleText("");
    }
  }, [type]);

  const classIcon = `icons ${"icons-" + type || ""} ${className || ""} ${size || ""}  ${
    (cursor && "cursor") || ""
  } ${"icons-" + color || ""} pulse`;

  const getTitle = () => {
    return title.visible ? (title.text.length > 0 ? title.text : titleText) : "";
  };
  return (
    <div className={classIcon} onClick={onClick} title={iconsTitle ? getTitle() : ""}>
      {
        // инфо
        type === "infoItem" && <InfoItem />
      }
      {
        // редактировать
        type === "edit" && <Edit />
      }
      {
        // удалить
        type === "remove" && <Remove />
      }
      {type === "fire" && <Fire />}
      {type === "settings" && <GearSettings />}
      {type === "viewCard" && <ViewCard />}
      {type === "crossBlackIcon" && <CrossBlackIcon />}
      {type === "crossBlack" && <CrossBlack />}
      {type === "filterIcon" && <FilterIcon />}
      {type === "voiceMicro" && <VoiceMicro />}
      {type === "paperIcon" && <PaperIcon />}
      {type === "emailOpen" && <EmailOpen />}
      {type === "tasksIcon" && <TasksIcon />}
      {type === "arrowLeft" && <ArrowLeft />}
      {type === "arrowTop" && <ArrowTop />}
      {type === "arrow" && <Arrow />}
      {type === "arrowDown" && <ArrowDown />}
      {type === "closeWhite" && <CloseWhite />}
      {type === "closeBlack" && <CloseWhite />}
      {type === "menu" && <Menu />}
      {type === "menuWhite" && <MenuWhite />}
      {type === "addToFolder" && <AddToFolder />}
      {type === "download" && <Download />}
      {type === "columnsChart" && <ColumnsChart />}
      {type === "bandsChart" && <BandsChart />}
      {type === "areasChart" && <AreasChart />}
      {type === "stepAreasChart" && <StepAreasChart />}
      {type === "linesChart" && <LinesChart />}
      {type === "pieChart" && <PieChart />}
      {type === "donutChart" && <DonutChart />}
      {type === "listChart" && <ListChart />}
      {
        // - показать отделы
        type === "structures" && <Structures />
      }
      {type === "copy" && <Copy />}
      {type === "timeIcon" && <TimeIcon />}
      {
        // дата
        type === "dateIcon" && <DateIcon />
      }
      {type === "phone" && <Phone />}
      {type === "mail" && <Email />}
      {type === "arrowDouble" && <ArrowDouble />}
      {
        // - в круге
        type === "circleMinus" && <CircleMinus />
      }
      {
        // + в круге
        type === "сirclePlus" && <CirclePlus />
      }
      {/* иконки для навигации */}
      {
        // все
        type === "all" && <All />
      }
      {
        // исходящие
        type === "outgoing" && <Outgoing />
      }
      {
        // входящие
        type === "incoming" && <Incoming />
      }
      {
        // документация проекта
        type === "docProject" && <DocProject />
      }
      {
        // документация общая
        type === "docAll" && <DocAll />
      }
      {
        // структура
        type === "structure" && <Structure />
      }
      {
        // группы
        type === "groups" && <Groups />
      }
      {
        // участники
        type === "members" && <Members />
      }
      {
        // компании
        type === "company" && <Company />
      }
      {
        // слои
        type === "layers" && <Layers />
      }
      {
        // инфо в проектах
        type === "infoProject" && <InfoItem />
      }
      {
        // документы в проектах
        type === "docs" && <Docs />
      }
      {
        // протоколы
        type === "protocols" && <Protocols />
      }
      {
        // графики
        type === "graf" && <Graf />
      }
      {
        // фотоотчеты
        type === "foto" && <Foto />
      }
      {
        // мои проекты
        type === "myProject" && <MyProject />
      }
      {
        // участник в проектах
        type === "memberProject" && <MemberProject />
      }
      {
        // все в проекты
        type === "allProject" && <AllProject />
      }
      {
        // админ
        type === "admin" && <Admin />
      }
      {
        // директор
        type === "director" && <Director />
      }
      {
        // пользователь
        (type === "user" || type === "infoUser") && <User />
      }
      {
        // исполнитель
        type === "customer" && <Customer />
      }
      {
        // исполнитель
        type === "performer" && <Performer />
      }
      {/* иконки для header */}
      {
        // аналитика
        type === "analytics" && <Analytics />
      }
      {
        // проекты
        (type === "projects" || type === "project") && <Projects />
      }
      {
        // сотрудники
        type === "employees" && <Employees />
      }
      {
        // задачи
        type === "tasks" && <Tasks />
      }
      {
        // сдача работ
        type === "deliveryWork" && <DeliveryWork />
      }
      {
        // календарь
        type === "calendar" && <Сalendar />
      }
      {
        // документы
        type === "documentation" && <Documents />
      }
      {
        // документы
        type === "chat" && <Сhat />
      }
      {
        // справка
        type === "reference" && <Reference />
      }
      {
        // профиль
        type === "profile" && <Profile />
      }
      {
        // уведомления
        type === "notifications" && <Notification />
      }
      {
        // глаз закрыт
        type === "exit" && <Exit />
      }
      {
        // поиск
        type === "search" && <Search />
      }
      {
        // стрелки для пагинации
        type === "arrowForPagination" && <ArrowForPagination />
      }
      {
        // глаз открыт
        type === "eyeOpen" && <EyeOpen />
      }
      {
        // глаз закрыт
        type === "eyeClose" && <EyeClose />
      }
      {
        // галочка - доступно
        type === "allowedIcon" && <AllowedIcon />
      }
      {
        // крест - заблокированн
        type === "bannedIcon" && <BannedIcon />
      }
      {
        // +
        type === "plus" && <Plus />
      }
      {
        // -
        type === "minus" && <Minus />
      }
      {
        // сетка средняя
        type === "grid" && <Grid />
      }
      {
        // сетка большая
        type === "big-grid" && <BigGrid />
      }
      {
        // сетка маленькая
        type === "small-grid" && <SmallGrid />
      }
      {
        // статус: выполненные
        type === "twoChechmark" && <TwoChechmark />
      }
      {
        // статус: в работе
        type === "circleOfArrows" && <CircleOfArrows />
      }
      {
        // статус: на проверке
        type === "pen" && <Pen />
      }
      {
        // статус: просроченные
        type === "overdue" && <Overdue />
      }
      {
        // статус: отмененнные
        type === "cancelled" && <Cancelled />
      }
      {
        // статус: назначенные
        type === "assigned" && <Assigned />
      }
      {
        // уведомление
        type === "notif" && <Notif />
      }
      {
        // слой
        type === "availabilityDoc" && <AvailabilityDoc />
      }
      {
        // замОк
        type === "lock" && <Lock />
      }
      {
        // отправка
        type === "magnifierMinus" && <MagnifierMinus />
      }
      {
        // отправка
        type === "magnifierPlus" && <MagnifierPlus />
      }
      {
        // лупа
        type === "magnifier" && <MagnifierBlack />
      }
      {
        // отправка
        type === "send" && <Send />
      }
      {
        // отправка
        type === "window" && <Window />
      }
      {
        // отправка
        type === "windowDouble" && <WindowDouble />
      }
      {
        // отправка
        type === "comment" && <Comment />
      }
      {
        // отправка
        type === "forvard" && <Forward />
      }
      {
        // папка
        type === "folder" && <Folder />
      }
      {
        // папка
        type === "folderOpen" && <FolderOpen />
      }
      {
        // zip
        type === "zip" && <Zip />
      }
      {
        // xlsx
        type === "xlsx" && <Xlsx />
      }
      {
        // xls
        type === "xls" && <Xls />
      }
      {
        // txt
        type === "txt" && <Txt />
      }
      {
        // ppt
        type === "ppt" && <Ppt />
      }
      {
        // pdf
        type === "pdf" && <Pdf />
      }
      {
        // noneFormat
        type === "noneFormat" && <NoneFormat />
      }
      {
        // noneFormat
        type === "mpp" && <Mpp />
      }
      {
        // jpg
        type === "jpg" && <Jpg />
      }
      {
        // jpeg
        type === "jpeg" && <Jpeg />
      }
      {
        // docx
        type === "docx" && <Docx />
      }
      {
        // docx
        type === "doc" && <Doc />
      }
      {
        // png
        type === "png" && <Png />
      }
      {
        // save
        type === "save" && <Save />
      }
      {
        // save
        type === "editSave" && <EditSave />
      }
      {
        // save
        type === "reset" && <Reset />
      }
      {
        // схематичный файл
        type === "file" && <File />
      }
      {
        // схематичная папка
        type === "folderClear" && <FolderClear />
      }
      {
        // схематичная папка
        type === "sortArrow" && <SortArrow />
      }
      {
        // двойная галочка
        type === "successDouble" && <SuccessDouble />
      }
      {
        // двойная галочка
        type === "confirm" && <Confirm />
      }

      {
        // смена статуса
        type === "changeSatus" && <ChangeSatus />
      }

      {
        //добавить юзера
        type === "addUser" && <AddUser />
      }
      {
        //добавить юзера
        type === "delUser" && <DelUser />
      }
      {
        //очистить чат
        type === "cleaning" && <Cleaning />
      }
      {
        //очистить чат
        type === "viewCardWhite" && <ViewCardWhite />
      }
      {
        //очистить чат
        type === "hand" && <Hand />
      }
      {
        //очистить чат
        type === "addEvent" && <AddEvent />
      }

      {
        // иконка юзера
        type === "userInfo" && <UserInfo />
      }

      {
        // иконка история
        type === "history" && <History />
      }

      {
        // сортировка
        type === "sort" && <Sort />
      }
    </div>
  );
};

export default Icons;
