import React from "react";
import Checkbox from "../../../Checkbox/Checkbox";
import "./CardRowProject.scss";
import Icons from "../../../Icons/Icons";
import { useSearch } from "../../../../hooks/useSearch";
import { useModal } from "../../../../hooks/useModal";
import { useHasPermissions } from "../../../../hooks/useHasPermissions";
import { toast } from "react-toastify";
import RowName from "../../../RowName/RowName";

function CardRowProject(props) {
  const { check, onCheck, number, info } = props;

  let { _id, name, customers, generalContractor, contractNumber, region, address, total_task } =
    info;

  const { edit, remove } = useHasPermissions("projectCard", info);

  const { changeModalVisible } = useModal();

  const { goToPage, getCurrentPage } = useSearch();

  const currentPage = getCurrentPage();

  const goToPageProject = async (edit) => {
    goToPage("project", { projectId: _id }, { edit: Number(edit) });
  };

  const goToPageTask = () => {
    toast.success(`Применен фильтр: ${name}`);
    goToPage(
      "tasks",
      {},
      {
        project: { label: name, value: _id },
      },
    );
  };

  return (
    <div className="cardRowProject">
      {/* <div className="cardRowProject-item number">
        <Checkbox onCheck={onCheck} check={check} />
        <span>{number}</span>
      </div> */}
      <RowName onClick={() => goToPageProject(false)} text={name} title={name} />

      <div className="cardRowProject-item contractNumber">
        <span title={contractNumber}>{contractNumber}</span>
      </div>
      <div className="cardRowProject-item customer">
        <span title={customers[0].name}>{customers[0].name} </span>
      </div>
      <div className="cardRowProject-item generalContractor">
        <span title={generalContractor.name}>{generalContractor.name} </span>
      </div>
      {/* <div className="cardRowProject-item dateStart">
        <span>{getFormatDate(timeline.start)}</span>
      </div>
      <div className="cardRowProject-item dateEnd">
        <span>{getFormatDate(timeline.end)}</span>
      </div>
      <div className="cardRowProject-item country">
        <span title={country.name}>{country.name}</span>
      </div> */}
      <div className="cardRowProject-item city">
        <span title={region.name}>{region.name}</span>
      </div>
      <div className="cardRowProject-item address">
        <span title={address}>{address}</span>
      </div>
      <div className="cardRowProject-item taskCount">
        <span onClick={goToPageTask}>{total_task}</span>
      </div>
      <div className="cardRowProject-item btns">
        <Icons
          onClick={() => goToPageProject(false)}
          type={"infoItem"}
          size={"md"}
          cursor
          title={{ visible: true, text: "Информация" }}
        />
        {edit && (
          <Icons
            type={"edit"}
            size={"md"}
            cursor
            onClick={() => goToPageProject(true)}
            title={{ visible: true, text: "Редактировать" }}
          />
        )}
        {remove && currentPage !== "userInfo:projects" && (
          <Icons
            onClick={() => changeModalVisible("deleteProject", true, info)}
            type={"remove"}
            size={"md"}
            cursor
            title={{ visible: true, text: "" }}
          />
        )}
      </div>
    </div>
  );
}

export default CardRowProject;
