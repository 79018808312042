import React from "react";
import Checkbox from "../../../../../../../components/Checkbox/Checkbox";
import { getFormatFIO } from "../../../../../../../utils/getFormatFIO.js";
import "./GroupsRow.scss";
import Icons from "../../../../../../../components/Icons/Icons";
import { useModal } from "../../../../../../../hooks/useModal";
import { countCompanyEmployess } from "../../../../../../../utils/countCompanyEmployess.js";
import { useHasPermissions } from "../../../../../../../hooks/useHasPermissions";

const GroupsRow = ({ check, onCheck, info, number }) => {
  const { director, name, profiles } = info;
  const { changeModalVisible } = useModal();
  const { edit, remove } = useHasPermissions("groupsCard", info);

  const onEvent = (name) => {
    if (name === "info") return changeModalVisible("infoGroup", true, info);
    if (name === "edit") return changeModalVisible("editGroup", true, info);
    if (name === "remove") return changeModalVisible("deleteGroup", true, info);
  };
  return (
    <li className="groupsRow">
      <ul className="groupsRow__list">
        {/* <li className="groupsRow__item groupsRow__item-number">
          <Checkbox check={check} onCheck={onCheck} />
          <span>{number}</span>
        </li> */}
        <li className="groupsRow__item groupsRow__item-group" onClick={() => onEvent("info")}>
          <span title={name}>{name}</span>
        </li>
        <li className="groupsRow__item groupsRow__item-director">
          <span title={getFormatFIO(director?.user)}>{getFormatFIO(director?.user)}</span>
        </li>

        {/* <li className="groupsRow__item groupsRow__item-сompany">
          <span title={"-"}>{"-"}</span>
        </li> */}
        <li className="groupsRow__item groupsRow__item-emploees">
          <span>{(countCompanyEmployess(profiles) || 0) + Number(!!director)}</span>
        </li>
        <li className="groupsRow__item groupsRow__item-btns">
          <button className="groupsRow__btn" onClick={() => onEvent("info")}>
            <Icons type={"infoItem"} size={"md"} cursor title={{ visible: true, text: "" }} />
          </button>
          {edit && (
            <button className="groupsRow__btn" onClick={() => onEvent("edit")}>
              <Icons type={"edit"} size={"md"} cursor title={{ visible: true, text: "" }} />
            </button>
          )}
          {remove && (
            <button className="groupsRow__btn" onClick={() => onEvent("remove")}>
              <Icons type={"remove"} size={"md"} cursor title={{ visible: true, text: "" }} />
            </button>
          )}
        </li>
      </ul>
    </li>
  );
};
export default GroupsRow;
