import React, { useState, useEffect } from "react";

import { getFormatFIO } from "../../utils/getFormatFIO.js";
import Icons from "../Icons/Icons";
import "./EmployeesListRow.scss";
import { useModal } from "../../hooks/useModal";
import { profilesParsing } from "../../utils/profilesParsing.js";
import { useSearch } from "../../hooks/useSearch";
import { useHasPermissions } from "../../hooks/useHasPermissions";

const EmployeesListRow = ({ info, btnRemove = true, onInfo, type = "employees" }) => {
  const { edit, remove } = useHasPermissions("employeesCard", info);

  const { email, phone, region, total_tasks, profiles, isLogin } = info;

  const { changeModalVisible } = useModal();
  const { goToPage } = useSearch();

  const onEvent = (name) => {
    if (name === "info") {
      return onInfo ? onInfo(info) : goToPage("userInfo", { userId: info._id });
    }
    if (name === "navigate") {
      return goToPage("userInfo", { userId: info._id });
    }

    if (name === "edit") return changeModalVisible("editEmployee", true, info);

    if (name === "remove") return changeModalVisible("deleteEmployee", true, info);
  };

  return (
    <li className={`employeesListRow ${isLogin ? "" : "not-login"} `}>
      <ul className={`employeesListRow__list ${type ? `employeesListRow__list-` + type : ""}`}>
        <li
          className="employeesListRow__item employeesListRow__item-fio"
          onClick={() => onEvent("navigate")}
        >
          <span title={getFormatFIO(info)}>{getFormatFIO(info)}</span>
        </li>
        {type === "employees" && (
          <li
            className={`employeesListRow__item ${
              profilesParsing("company", profiles) ? "employeesListRow__item-сompany" : ""
            }`}
          >
            {profilesParsing("company", profiles) && (
              <span title={profilesParsing("company", profiles)}>
                {profilesParsing("company", profiles)}
              </span>
            )}
          </li>
        )}
        <li className="employeesListRow__item employeesListRow__item-position">
          <span title={profilesParsing("position", profiles)}>
            {profilesParsing("position", profiles)}
          </span>
        </li>
        <li className="employeesListRow__item employeesListRow__item-email">
          <a href={`mailto:${email}`} title={email}>
            {email}
          </a>
        </li>
        <li className="employeesListRow__item employeesListRow__item-tel">
          <a href={`tel: ${phone}`} title={phone}>
            {phone}
          </a>
        </li>

        {/* <li className="employeesListRow__item employeesListRow__item-region">
          <span title={region.name}>{region.name}</span>
        </li> */}

        <li className="employeesListRow__item employeesListRow__item-task">
          <span title={total_tasks || 0}>{total_tasks || 0}</span>
        </li>
        <li className="employeesListRow__item employeesListRow__item-btns">
          <button className="employeesListRow__btn" onClick={() => onEvent("info")}>
            <Icons type={"infoItem"} size={"md"} cursor title={{ visible: true, text: "" }} />
          </button>
          {edit && (
            <button className="employeesListRow__btn" onClick={() => onEvent("edit")}>
              <Icons type={"edit"} size={"md"} cursor title={{ visible: true, text: "" }} />
            </button>
          )}
          {!!btnRemove && remove && (
            <button className="employeesListRow__btn" onClick={() => onEvent("remove")}>
              <Icons type={"remove"} size={"md"} cursor title={{ visible: true, text: "" }} />
            </button>
          )}
        </li>
      </ul>
    </li>
  );
};
export default EmployeesListRow;
