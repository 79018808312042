import React from "react";
import { ResponsiveSunburst } from "@nivo/sunburst";
//  "#db1f35", red
import "./Sunburst.scss";
// const dataSunburst = {
//   name: "Задачи",
//   color: "hsl(220, 70%, 50%)",
//   test: "hsl(270, 100%, 50%)",
//   id: 0,
//   children: [
//     {
//       name: "Назначенные",
//       color: "hsl(270, 100%, 50%)",
//       test: "hsl(270, 100%, 50%)",
//       id: "assigned",
//       key: "assigned",
//       id: 1,
//       children: [
//         {
//           name: "В рамках срока",
//           color: "hsl(270, 100%, 50%)",
//           test: "hsl(270, 100%, 50%)",
//           loc: taskReports?.assigned?.assigned,
//           key: "assignedassigned",
//           id: 11,
//         },
//         {
//           name: "С нарушением срока",
//           color: "hsl(270, 100%, 50%)",
//           test: "hsl(270, 100%, 50%)",
//           loc: taskReports?.assigned?.overdue,
//           key: "overdue",
//           id: 12,
//         },
//       ],
//     },
//     {
//       name: "Отмененные",
//       color: "hsl(0, 0%, 75%)",
//       test: "hsl(270, 100%, 50%)",
//       key: "rejected",
//       id: 2,
//       children: [
//         {
//           name: "В рамках срока",
//           color: "hsl(0, 0%, 75%)",
//           loc: taskReports?.rejected?.rejected,
//           test: "hsl(270, 100%, 50%)",
//           key: "rejectedrejected",
//           id: 21,
//         },
//         {
//           name: "С нарушением срока",
//           color: "hsl(0, 0%, 75%)",
//           loc: taskReports?.rejected?.overdue,
//           test: "hsl(270, 100%, 50%)",
//           key: "overdue",
//           id: 22,
//         },
//       ],
//     },
//     {
//       name: "В работе",
//       color: "hsl(210, 95%, 29%)",
//       test: "hsl(270, 100%, 50%)",
//       key: "atWork",
//       id: 3,
//       children: [
//         {
//           name: "В рамках срока",
//           color: "hsl(210, 95%, 29%)",
//           loc: taskReports?.atWork?.atWork,
//           test: "hsl(270, 100%, 50%)",
//           key: "atWorkatWork",
//           id: 31,
//         },
//         {
//           name: "С нарушением срока",
//           color: "hsl(210, 95%, 29%)",
//           loc: taskReports?.atWork?.overdue,
//           test: "hsl(270, 100%, 50%)",
//           key: "overdue",
//           id: 32,
//         },
//       ],
//     },
//     {
//       name: "На проверке",
//       color: "hsl(43, 100%, 43%)",
//       test: "hsl(270, 100%, 50%)",
//       key: "onVerification",
//       id: 4,
//       children: [
//         {
//           name: "В рамках срока",
//           color: "hsl(43, 100%, 43%)",
//           loc: taskReports?.onVerification?.onVerification,
//           key: "onVerificationonVerification",
//           id: 41,
//         },
//         {
//           name: "С нарушением срока",
//           color: "hsl(43, 100%, 43%)",
//           loc: taskReports?.onVerification?.overdue,
//           test: "hsl(270, 100%, 50%)",
//           key: "overdue",
//           id: 42,
//         },
//       ],
//     },

//     {
//       name: "Выполненные",
//       color: "hsl(144, 80%, 43%)",
//       test: "hsl(270, 100%, 50%)",
//       key: "completed",
//       id: 5,
//       children: [
//         {
//           name: "В рамках срока",
//           color: "hsl(144, 80%, 43%)",
//           test: "hsl(270, 100%, 50%)",
//           loc: taskReports?.completed?.completed,
//           key: "completedcompleted",
//           id: 51,
//         },
//         {
//           name: "С нарушением срока",
//           color: "hsl(144, 80%, 43%)",
//           test: "hsl(270, 100%, 50%)",
//           loc: taskReports?.completed?.overdue,
//           key: "overdue",
//           id: 52,
//         },
//       ],
//     },
//   ],
// };
const Sunburst = ({ data, onClick }) => {
  return (
    <div className="sunburst">
      <ResponsiveSunburst
        colors={["#7f00ff", "grey", "#04488e", "#dc9d00", "#16c35b"]}
        data={data}
        margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
        id="name"
        value="loc"
        cornerRadius={7}
        borderWidth={20}
        height={280}
        valueFormat=" >-" // отображение в дефолтных числах
        borderColor={{ theme: "background" }}
        // colors={{ scheme: "" }}
        // colorBy={(node) => (node.data.children.key === "rejected" ? "#ff0000" : "#000000")}
        childColor={{
          from: "color",
          modifiers: [["brighter", 0.6]],
        }}
        enableArcLabels={true}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor="#ffffff"
        // arcLabelsTextColor={{
        //   from: "color",
        //   modifiers: [["darker", 5]],
        // }}
        onClick={(e) => onClick && onClick(e)}
      />
    </div>
  );
};

export default Sunburst;
