import React, { useState, useEffect } from "react";
import "./CompaniesRow.scss";
import Checkbox from "../../../../../../../components/Checkbox/Checkbox";
import { getFormatFIO } from "../../../../../../../utils/getFormatFIO.js";
import { transformTypeCompany } from "../../../../../../../utils/transformTypeCompany.js";
import Icons from "../../../../../../../components/Icons/Icons";
import { countCompanyEmployess } from "../../../../../../../utils/countCompanyEmployess.js";
import { useHasPermissions } from "../../../../../../../hooks/useHasPermissions";
import { useSearch } from "../../../../../../../hooks/useSearch.js";
import { useNavigate } from "react-router-dom";

const CompaniesRow = ({ check, onCheck, changeModalVisible, info, number }) => {
  const { edit, remove } = useHasPermissions("companiesCard", info);

  const { name, director, region, email, phone, profiles, type, _id } = info;

  const navigate = useNavigate();

  const onEvent = (name) => {
    if (name === "navigate") navigate(`/company/${_id}/baseInfo`);
    if (name === "info") return changeModalVisible("infoCompany", true, info);
    if (name === "edit") return changeModalVisible("infoCompany", true, info, true);
    if (name === "remove") return changeModalVisible("deleteCompany", true, info);
  };

  return (
    <li className="companiesRow">
      <ul className="companiesRow__list">
        <li
          className="companiesRow__item companiesRow__item-сompany"
          onClick={() => onEvent("navigate")}
        >
          <span title={name}>{name}</span>
        </li>
        <li className="companiesRow__item companiesRow__item-manager">
          <span title={getFormatFIO(director)}>{getFormatFIO(director, "FIO")}</span>
        </li>
        <li className="companiesRow__item companiesRow__item-region">
          <span title={region.name}>{region.name}</span>
        </li>
        <li className="companiesRow__item companiesRow__item-email">
          <a title={email} href={`mailto:${email}`}>
            {email}
          </a>
        </li>
        <li className="companiesRow__item companiesRow__item-tel">
          <a title={phone} href={`tel: ${phone}`}>
            {phone}
          </a>
        </li>
        <li className="companiesRow__item companiesRow__item-numberEmployees">
          <span title={countCompanyEmployess(profiles) + 1 || 1}>
            {countCompanyEmployess(profiles) || 1}{" "}
          </span>
        </li>
        <li className="companiesRow__item companiesRow__item-type">
          <span title={transformTypeCompany(type)}>{transformTypeCompany(type)}</span>
        </li>
        <li className="companiesRow__item companiesRow__item-btns">
          <button className="companiesRow__btn" onClick={() => onEvent("info")}>
            <Icons type={"infoItem"} size={"md"} cursor title={{ visible: true, text: "" }} />
          </button>
          {edit && (
            <button className="companiesRow__btn" onClick={() => onEvent("edit")}>
              <Icons type={"edit"} size={"md"} cursor title={{ visible: true, text: "" }} />
            </button>
          )}
          {remove && (
            <button className="companiesRow__btn" onClick={() => onEvent("remove")}>
              <Icons type={"remove"} size={"md"} cursor title={{ visible: true, text: "" }} />
            </button>
          )}
        </li>
      </ul>
    </li>
  );
};
export default CompaniesRow;
