import React, { useState, useEffect } from "react";
import { useModal } from "../../../../hooks/useModal";
import { getBaseUrl } from "../../../../utils/getBaseUrl";
import Icons from "../../../Icons/Icons";
import Screen from "../../../Screen/Screen";
import "./DocumentationItemRow.scss";
import axios from "../../../../axios/axios";
import Checkbox from "../../../Checkbox/Checkbox";
import DocIcons from "../../../DocIcons/DocIcons";
import { getFormatFIO } from "../../../../utils/getFormatFIO";
import { useSearch } from "../../../../hooks/useSearch";

const DocumentationItemRow = ({ info, check, onCheck, type, father, changeCurrentFolder }) => {
  const { name, size, type_file, path_to_doc, creator } = info;

  const { changeModalVisible } = useModal();

  const { setQueryParam, queries } = useSearch();

  const getFolder = (folder) => {
    changeCurrentFolder && changeCurrentFolder(folder);

    if (queries.chapter === "companies") {
      setQueryParam({
        chapter: "company",
        companyId: folder.ref_id,
        father: folder._id,
      });
    } else if (queries.chapter === "projects") {
      setQueryParam({
        chapter: "project",
        projectId: folder.ref_id,
        father: folder._id,
      });
    } else {
      setQueryParam({ father: folder._id });
    }
  };

  const [typeIcon, setTypeIcon] = useState("");

  const downloadFile = (e) => {
    e.preventDefault();
    axios
      .get(getBaseUrl() + "/folders" + path_to_doc, { responseType: "blob" })
      .then((response) => {
        const link = document.createElement("a");
        const blob = new Blob([response.data]);
        let urll = window.URL.createObjectURL(blob);
        link.href = urll;
        link.download = info.name;
        link.click();
        setTimeout(() => {
          window.URL.revokeObjectURL(urll);
          document.body.removeChild(link);
        }, 0);
        URL.revokeObjectURL(link.href);
      })
      .catch(console.error);
  };

  useEffect(() => {
    if (father) {
      if (path_to_doc.includes("png")) {
        return setTypeIcon("png");
      } else if (path_to_doc.includes("docx")) {
        return setTypeIcon("docx");
      } else if (path_to_doc.includes("doc")) {
        return setTypeIcon("doc");
      } else if (path_to_doc.includes("txt")) {
        return setTypeIcon("txt");
      } else if (path_to_doc.includes("ppt")) {
        return setTypeIcon("ppt");
      } else if (path_to_doc.includes("pdf")) {
        return setTypeIcon("pdf");
      } else if (path_to_doc.includes("xlsx")) {
        return setTypeIcon("xlsx");
      } else if (path_to_doc.includes("xls")) {
        return setTypeIcon("xls");
      } else if (path_to_doc.includes("jpeg")) {
        return setTypeIcon("jpeg");
      } else if (path_to_doc.includes("jpg")) {
        return setTypeIcon("jpg");
      } else if (path_to_doc.includes("mpp")) {
        return setTypeIcon("mpp");
      } else if (path_to_doc.includes("zip")) {
        return setTypeIcon("zip");
      } else {
        setTypeIcon("");
      }
    }
  }, [type_file, father]);

  const onEvent = (name) => {
    if (type === "folder") {
      if (name === "info") return changeModalVisible("infoFolder", true, info);
      if (name === "open") return getFolder(info);
      if (name === "edit") return changeModalVisible("infoFolder", true, info, true);
      if (name === "delete") return changeModalVisible("deleteFolder", true, info);
      if (name === "add") return changeModalVisible("addDocument", true, { folder: info._id });
      if (name === "copy") return changeModalVisible("addToFolder", true, info, false, true);
      if (name === "addToFolder") return changeModalVisible("addToFolder", true, info, true, false);
    } else {
      if (name === "info") return changeModalVisible("infoFile", true, info);
      if (name === "open") return changeModalVisible("viewDoc", true, info, false, true);
      if (name === "edit") return changeModalVisible("infoFile", true, info, true);
      if (name === "copy") return changeModalVisible("addToFolder", true, info, false, true);
      if (name === "addToFolder") return changeModalVisible("addToFolder", true, info, true, false);
      if (name === "delete") return changeModalVisible("deleteDocument", true, info);
    }
  };

  return (
    <li className="documentationItemRow">
      <ul className="documentationItemRow-content">
        <li className="documentationItemRow-content-item check">
          {!!father && <Checkbox check={check} onCheck={onCheck} />}
        </li>
        <li className="documentationItemRow-content-item icon">
          {type === "folder" ? (
            <Icons
              type={"folder"}
              size={"xl"}
              title={{ visible: true, text: `Папка: ${name}` }}
              onClick={() => onEvent("open")}
              cursor
            />
          ) : (
            <DocIcons
              size={"xl"}
              type={path_to_doc}
              onClick={() => onEvent("open")}
              titleText={name}
            />
          )}
        </li>
        <li
          className="documentationItemRow-content-item name"
          onClick={() => (type === "folder" ? onEvent("open") : onEvent("info"))}
        >
          <span title={name}>{name}</span>
        </li>
        <li className="documentationItemRow-content-item">
          <span title={typeIcon}>{type === "folder" ? "папка" : typeIcon}</span>
        </li>
        <li className="documentationItemRow-content-item">
          <span title={size ? `${size} mb` : ""}>{size ? `${size} mb` : ""}</span>
        </li>
        <li className="documentationItemRow-content-item">
          <span title={getFormatFIO(creator) || "-"}>{getFormatFIO(creator) || "-"}</span>
        </li>
        {/* <li className="documentationItemRow-content-item">
          <span title={getFormatDateTime(createdAt) || "-"}>
            {getFormatDateTime(createdAt) || "-"}
          </span>
        </li> */}
        <div className="documentationItemRow-content-item btns">
          <Screen size={"lg"}>
            {!father ? (
              <Icons
                type={"infoItem"}
                size={"md"}
                cursor
                onClick={() => onEvent("info")}
                title={{ visible: true, text: "Просмотр" }}
              />
            ) : (
              <>
                {type === "file" ? (
                  <>
                    <Icons
                      type={"download"}
                      size={"md"}
                      cursor
                      onClick={(e) => downloadFile(e)}
                      title={{ visible: true, text: "" }}
                    />
                    <Icons
                      type={"edit"}
                      size={"md"}
                      cursor
                      onClick={() => onEvent("edit")}
                      title={{ visible: true, text: "" }}
                    />
                  </>
                ) : (
                  <Icons
                    type={"edit"}
                    size={"md"}
                    cursor
                    onClick={() => onEvent("edit")}
                    title={{ visible: true, text: "" }}
                  />
                )}
                {type === "file" ? (
                  <Icons
                    type={"file"}
                    size={"md"}
                    cursor
                    onClick={() => onEvent("open")}
                    title={{ visible: true, text: "Открыть файл" }}
                  />
                ) : (
                  <Icons
                    type={"folderClear"}
                    size={"md"}
                    cursor
                    onClick={() => onEvent("open")}
                    title={{ visible: true, text: "Открыть папку" }}
                  />
                )}
                <Icons
                  type={"infoItem"}
                  size={"md"}
                  cursor
                  onClick={() => onEvent("info")}
                  title={{ visible: true, text: "Просмотр информации" }}
                />
                <Icons
                  type={"copy"}
                  size={"md"}
                  cursor
                  onClick={() => onEvent("copy")}
                  title={{ visible: true, text: "" }}
                />
                <Icons
                  onClick={() => onEvent("addToFolder")}
                  type={"addToFolder"}
                  size={"md"}
                  cursor
                  title={{ visible: true, text: "" }}
                />
                <Icons
                  onClick={() => onEvent("delete")}
                  type={"remove"}
                  size={"md"}
                  cursor
                  title={{ visible: true, text: "" }}
                />
              </>
            )}
          </Screen>
          <Screen size={"lg"} down>
            <Icons
              type={"menu"}
              size={"md"}
              cursor
              onClick={() => changeModalVisible("menu", true, info, false, true)}
            />
          </Screen>
        </div>
      </ul>
    </li>
  );
};

export default DocumentationItemRow;
