import React, { useState } from "react";
import "./CardColumnProject.scss";
import noImage from "../../assets/images/pages/Projects/no-image.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { getFormatDate } from "../../utils/getFormatDate";
import { useNavigate } from "react-router-dom";
import DonutForProjecCard from "./components/DonutForProjecCard/DonutForProjecCard";
import Icons from "../Icons/Icons";
import { useModal } from "../../hooks/useModal";
import { getFullPath } from "../../utils/getFullPath";
import Screen from "../Screen/Screen";
import { toast } from "react-toastify";
import { transformStatus } from "../../utils/transformStatus";
import { useSearch } from "../../hooks/useSearch";
import { useHasPermissions } from "../../hooks/useHasPermissions";

function CardColumn(props) {
  const { number, info } = props;
  let {
    _id,
    photos,
    name,
    customers,
    generalContractor,
    tasks,
    contract_start,
    contract_end,
    completed,
  } = info;
  const { edit, remove } = useHasPermissions("projectCard", info);
  const { changeModalVisible } = useModal();
  const { goToPage } = useSearch();
  const navigate = useNavigate();
  const [swiper, setSwiper] = useState(null);

  const goToPageProject = (edit) => {
    goToPage("project", { projectId: _id }, { edit: Number(edit) });
  };

  const goToPageTask = (status) => {
    toast.success(`Применен фильтр: ${transformStatus(status).name}, ${name}`);
    goToPage(
      "tasks",
      {},
      {
        status: { label: transformStatus(status).name, value: status },
        project: { label: name, value: _id },
      },
    );
  };

  const sumTasks = (obj) => {
    let sum = 0;
    for (let key in obj) {
      if (key !== "overdue") {
        sum += obj[key];
      }
    }
    return sum;
  };

  return (
    <div
      className={`cardColumn ${completed ? "completed" : ""}`}
      title={completed ? "Проект завершен" : ""}
    >
      <div className="cardColumn-swiper">
        <Swiper
          className={`swiper${_id}`}
          loop={true}
          onSwiper={(s) => {
            setSwiper(s);
          }}
        >
          {photos?.length ? (
            <>
              {photos.map((img) => (
                <SwiperSlide key={img}>
                  <div
                    className="cardColumn-swiper-slideImg"
                    style={{
                      backgroundImage: `url(${getFullPath(img, "project")})`,
                    }}
                  ></div>
                </SwiperSlide>
              ))}
            </>
          ) : (
            <SwiperSlide>
              <div
                className="cardColumn-swiper-slideImg no-image"
                style={{
                  backgroundImage: `url(${noImage})`,
                }}
              ></div>
            </SwiperSlide>
          )}
        </Swiper>
        {!!photos?.length && (
          <>
            <div onClick={() => swiper.slideNext()} className="cardColumn-swiper-next">
              <Icons
                type={"arrow"}
                size={"md"}
                cursor
                title={{ visible: true, text: "Следующее" }}
              />
            </div>
            <div onClick={() => swiper.slidePrev()} className="cardColumn-swiper-prev">
              <Icons
                type={"arrow"}
                size={"md"}
                cursor
                title={{ visible: true, text: "Предыдущее" }}
              />
            </div>
          </>
        )}
      </div>
      <div className="cardColumn-content">
        {/* <div className="cardColumn-content-number">
          <span>№ {number}</span>
        </div> */}
        <div onClick={() => goToPageProject(false)} className="cardColumn-content-info">
          <div className="cardColumn-content-info-header">
            <span className="cardColumn-content-info-header-title">{name}</span>
          </div>
          <div className="cardColumn-content-info-item">
            <span className="cardColumn-content-info-item-title">Заказчик:</span>
            <span className="cardColumn-content-info-item-text">{customers[0].name}</span>
          </div>
          <div className="cardColumn-content-info-item">
            <span className="cardColumn-content-info-item-title">Ген. подрядчик:</span>
            <span className="cardColumn-content-info-item-text">{generalContractor.name}</span>
          </div>
          <span className="cardColumn-content-info-item">
            <span className="cardColumn-content-info-item-title">Сроки проекта:</span>
            <span className="cardColumn-content-info-item-text">
              {getFormatDate(contract_start)} - {getFormatDate(contract_end)}
            </span>
          </span>
        </div>
        <div className="cardColumn-content-tasks">
          <div className="cardColumn-content-tasks-desc">
            <span className="cardColumn-content-tasks-desc-title">Задачи:</span>

            <div
              onClick={() => goToPageTask("onVerification")}
              className="cardColumn-content-tasks-desc-li onVerification"
            >
              <div className="cardColumn-content-tasks-desc-li-circle"></div>
              <span className="cardColumn-content-tasks-desc-li-title">На проверке:</span>
              <span className="cardColumn-content-tasks-desc-li-count">
                {tasks?.onVerification}
              </span>
            </div>
            <div
              onClick={() => goToPageTask("inWork")}
              className="cardColumn-content-tasks-desc-li inWork"
            >
              <div className="cardColumn-content-tasks-desc-li-circle"></div>
              <span className="cardColumn-content-tasks-desc-li-title">В работе:</span>
              <span className="cardColumn-content-tasks-desc-li-count">{tasks?.atWork}</span>
            </div>
            <div
              onClick={() => goToPageTask("completed")}
              className="cardColumn-content-tasks-desc-li completed"
            >
              <div className="cardColumn-content-tasks-desc-li-circle"></div>
              <span className="cardColumn-content-tasks-desc-li-title">Выполнено:</span>
              <span className="cardColumn-content-tasks-desc-li-count">{tasks?.completed}</span>
            </div>

            <div
              onClick={() => goToPageTask("rejected")}
              className="cardColumn-content-tasks-desc-li rejected"
            >
              <div className="cardColumn-content-tasks-desc-li-circle"></div>
              <span className="cardColumn-content-tasks-desc-li-title">Отклонено:</span>
              <span className="cardColumn-content-tasks-desc-li-count">{tasks?.rejected}</span>
            </div>
            <div
              onClick={() => goToPageTask("assigned")}
              className="cardColumn-content-tasks-desc-li assigned"
            >
              <div className="cardColumn-content-tasks-desc-li-circle"></div>
              <span className="cardColumn-content-tasks-desc-li-title">Назначенные:</span>
              <span className="cardColumn-content-tasks-desc-li-count">{tasks?.assigned}</span>
            </div>
            <div
              onClick={() => goToPageTask("overdue")}
              className="cardColumn-content-tasks-desc-li "
            >
              {/* <div className="cardColumn-content-tasks-desc-li-circle"></div> */}
              <span className="cardColumn-content-tasks-desc-li-title">Всего:</span>
              <span className="cardColumn-content-tasks-desc-li-count all">{sumTasks(tasks)}</span>
              {/* <span className="cardColumn-content-tasks-desc-li-count overdue">{`(${tasks?.overdue})`}</span> */}
            </div>
          </div>
          <div className="cardColumn-content-tasks-chart">
            {/* <img src={chartForItem} alt="chart" /> */}
            {!!tasks && <DonutForProjecCard tasks={tasks} />}
          </div>
        </div>
        <Screen size={"lg"} down>
          <div className="cardColumn-content-menu">
            <div className="cardColumn-content-menu-btn">
              <Icons
                type={"menu"}
                size={"m-md"}
                cursor
                onClick={() => changeModalVisible("menu", true, info, false, false, "projectCard")}
              />
            </div>
          </div>
        </Screen>
        <Screen size={"lg"}>
          <div
            onClick={() => changeModalVisible("deleteProject", true, info)}
            className="cardColumn-content-remove"
          >
            {remove && (
              <Icons type={"remove"} size={"md"} cursor title={{ visible: true, text: "" }} />
            )}
          </div>
          <div onClick={() => goToPageProject(true)} className="cardColumn-content-edit">
            {edit && <Icons type={"edit"} size={"md"} cursor title={{ visible: true, text: "" }} />}
          </div>
        </Screen>
      </div>
    </div>
  );
}

export default CardColumn;
